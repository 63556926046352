


$c_white: #ffffff;
$c_grey: #f2f2f2;
$c_tint: #F8F7F7;
$c_black: #000000;
$c_red: #E4032E;
$c_red_dark: #92031f;
$c_main: #304561;
$c_main_dark: #A02E04;
$c_blue: #6183B0;
$c_blue_dark: #232F40;
$c_logo: #E4032E;
$c_gold: #ae9a65;


 

@import 'karolis';
