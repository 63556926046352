


@font-face {
	font-family: "MaisonNeue";
	src: url("/app/themes/ridersbasketball/static/fonts/MaisonNeueWEB-Bold.woff") format("woff");
}
@font-face {
	font-family: "Abolition";
	src: url("/app/themes/ridersbasketball/static/fonts/Abolition-Regular.otf") format("opentype");
}

h1,h2,h3,h4,h5 {
	font-family: 'MaisonNeue';
}
body, html {
	margin: 0;
	height: 100%;
	display: block;
	font-family: 'Roboto', sans-serif;

}

.container {
	max-width: 1200px;
	margin-left:	auto;
	margin-right:	auto;
}

.small-container {
	max-width: 1000px;
	margin-left:	auto;
	margin-right:	auto;
}

/* region ------ Base ------*/

.clear:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ".";
    clear: both;
    height: 0;
    float:none;
}

a {
	-webkit-transition: 0.4s;
    transition: 0.4s;
    text-decoration: none;
}

input,
select {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 1 */
	line-height: 1.15; /* 1 */
	margin: 0; /* 2 */
}

input { /* 1 */
	overflow: visible;
}

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}


/* #endregion ------ Base ------- */

/* region ------ variables ------*/


/* region ------ mixin ------*/

@mixin button-style {
	text-align: center;
	font-size: 1.3em;
	padding: 12px;
	text-transform: uppercase;
	color: $c_white;
	background-color: $c_red;
	font-family: 'Abolition', sans-serif;
	transition: 0.4s;
}

@mixin ghost_button {
    text-align: center;
    border: 2px solid $c_red;
    font-size: 1.5em;
    padding: 8px;
    color: $c_red;
    text-transform: uppercase;
    font-family: 'Abolition', sans-serif;
    margin: 0 auto;
}


@mixin sub-heading {
    text-align: center;
    font-size: 2em;
    text-transform: uppercase;
    color: #000000;
    font-family: 'Abolition', sans-serif;
}


@mixin button-hover {
background-color: #af9a70;
cursor: pointer;
-webkit-transition: 0.4s;
transition: 0.4s;
}

@font-face {
	font-family: "Mystique";
	src: url("Mystique-Regular.otf");
}

/* #endregion ------ mixin ------- */


/* region ------ breakpoints ------*/

@mixin breakpoint($point) {
    @if $point == desktop_large {
      @media (min-width: 2560px) { @content ; }
   }
    @else if $point == desktop {
      @media (min-width: 1400px) { @content ; }
   }
    @else if $point == laptop {
      @media (min-width: 1024px) { @content ; }
   }
     @else if $point == tablet {
      @media (min-width: 768px) { @content ; }
   }
    @else if $point == phablet {
      @media (min-width: 425px)  { @content ; }
   }
   @else if $point == mobile {
      @media (max-width: 424px)  { @content ; }

   }
 }


 /* #endregion ------ breakpoints ------- */



/* region ------ Typography ------*/


h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.3em 0;
    padding: 0 0 0.3em 0;
    line-height: 1em;
    text-transform: uppercase;
}

h1 {
    margin: 0 auto;
    font-size: 1.6em;
    text-transform: uppercase;
    padding: 0;
    max-width: 1200px;
    font-weight: 900;
}

h2 { /*font-size: 30px;*/
    text-align: left;
    color: #000000;
    font-family: Abolition;
    font-weight: 500;
    font-size: 2em;

}

h3 { /*font-size: 25px;*/
    font-size: 1.3em;
    font-weight: 300; /*font-family: 'Open Sans', sans-serif;*/
    margin-bottom: 0.5em;
    font-weight: 300;
    text-transform: uppercase;
}

h4 { /*font-size: 20px;*/
    font-size: 1.3em;
    font-weight: 300;
    text-transform: uppercase; /*border-bottom: 1px solid #ccc; display: inline-block; margin-bottom: 0.8em;*/
}

h5 { /*font-size: 17px;*/
    font-size: 1.2em;
    font-weight: 300; /*text-transform: uppercase; border-bottom: 1px solid #ccc; display: inline-block; margin-bottom: 0.8em;*/
}

h6 { /*font-size: 15px;*/
    font-size: 1.1em;
    font-weight: 300; /*text-transform: uppercase; border-bottom: 1px solid #ccc; display: inline-block; margin-bottom: 0.8em;*/
}


p, li {
	line-height: 1.8;
}


/* #endregion ------ Typography ------- */



/* region ------ Header ------*/

header#masthead {
    z-index: 9999;
    position: relative;
}
@media(max-width: 767px) {

#page {
	margin-top: 61px;
}

}

.mobile_header {
    background-color: $c_red;
    padding: 0 10px;
		display: none;
		@media(max-width: 767px) {
			display: block;
			position: fixed;
			width: 100%;
			top: 0;
		}
    .mobile_logo {
		.mob_logo_wrap {
		    padding: 10px 5% 10px 3%;
		    .mobile-menu-button {
					width: 45px;
			    cursor: pointer;
			    display: none;
			    z-index: 3;
			    float: right;

				@media(max-width: 767px) {
					display: block;
				}

				span {
					width: 45px;
					height: 3px;
					display: block;
					position: relative;
					margin: 7px 0 7px auto;
					background: $c_white;
					transition: 300ms all ease;
				}
				&:hover {
					span:nth-child(1) {
							width: 40px;
					}
					span:nth-child(2) {
							width: 35px;
					}
					span:nth-child(3) {
							width: 30px;
					}

				}
			}
		}
	}
	.mobile-navigation {
		display: none;
	}




.mobile_menu_inner_wrap {
    width: 90%;
    margin: 0 auto;
}

.mobile-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
		background: $c_black;
		display: none;
		overflow-y: scroll;
    .close {
		position: fixed;
		top: 5vh;
		right: 5vw;
		cursor: pointer;
		svg {
			width: 25px;
			* {
				fill: $c_white;
			}

			&:hover {
				* {
					fill: $c_red;
				}
			}
		}
	}


		.social_icons {
				margin-top: 30px;
				margin-bottom: 60px;
		    .icon_wrap {

			   		display: grid;
				    grid-template-columns: repeat(4, 1fr);
				    grid-gap: 0px;
				    margin: 0 auto;
				    width: 100%;
				    max-width: 250px;

				    .icon {
					    height: 50px;
					    width: 50px;
					    border: 1px solid $c_white;
					    margin: 0 auto;
					    padding: 8px;
					}
			}
	    }


	.menu-primary-menu-container {
   margin-top: 10vh;

    ul {
	    margin: 0;
			padding: 0;

	        li {
						text-align: center;
					 a {
					    color: white;
					    text-decoration: none;
					    text-transform: uppercase;
							font-family: Abolition, sans-serif;
					    font-size: 32px;
					}
				}
    	}


	}


	.mobile_basketball_btn {
		padding: 10px;
			.basketball_btn {
		    color: white !important;
		    background-color: $c_red;
		    border-radius: 30px;
		    font-weight: 300;
		    padding: 10px 20px;
		    text-align: center;
		    text-transform: uppercase;
				font-family: Abolition, sans-serif;
				font-size: 32px;

		}
	}



	.mobile_pre_header_links {
	    margin: 0 auto;
	    padding: 40px 10px;
	    width: 100%;
	    .mobile_pre_header_wrap {
		    display: grid;
		    grid-template-columns: repeat(2, 1fr);
		    grid-gap: 10px;
		    .pre_header_button {
			    color: white;
			    text-decoration: none;
			    text-transform: uppercase;
			    font-family: Abolition;
			    font-size: 16px;
			    border: 1px solid $c_white;
			    padding: 12px;
			    text-align: center;
					a {
						color: $c_white;
					}
			}
		}
	}





    .menu {
	    list-style: none;
	    padding: 0;
	    .menu-item {
		    text-align: center;
		    font-size: 2em;
		    margin: 25px auto;
		    font-size: 1.2em;
		    padding: 8px 9px;
		    border: 2px solid $c_white;

		    a {
			    text-decoration: none;
			    color: $c_white;
			    &:hover {
				    color: $c_red;
			    }
		    }
	    }
    }

}

}//mobile_header :end:


.preheader {
	display:none;

	@include breakpoint(phablet) {
            display:none;
            }

    @include breakpoint(tablet) {
            display:block;
            }

    @include breakpoint(laptop) {
            display:block;
            }



    padding: 0 10px;
    background-color: $c_black;

    .preheader_wrap	{
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 10px;
		padding: 0 10px;
		max-width: 1200px;
		margin: 0 auto;
	    .title {

			a{
				font-family: 'Abolition', sans-serif;
			font-weight: 400;
			color: $c_white;
			margin-top: 3px;
			padding: 0px;
			text-transform: uppercase;
			font-size: 1.5em;
			}
	    }

	    .social_icons {
		    .icon_wrap {
			    .icon {
				    height: 25px;
				    width: 25px;
				    border: 1px solid $c_white;
				    display: inline-block;
				    margin-top: 9px;
				    padding: 3px;
						transition: 300ms ease all;
						a {
					    display: flex;
					    width: 100%;
					    height: 100%;
					    justify-content: center;
					    align-items: center;
					    align-content: center;
						}
						svg {
							width: 100%;

							transition: 300ms ease all;
							*	{
								transition: 300ms ease all;
							}
						}
				    &:hover {
							border: 1px solid $c_red;
					    svg {
								* {
									fill: $c_red !important;
								}
							}
				    }
			    }
		    }
	    }


	    .preheader_items {
		    grid-column: span 4 / 7;
		    .preheader_items_wrap {
			    float:right;

			    .preheader_item {
			        font-weight: 400;
			        font-size:18px;
					    font-family: 'Abolition', sans-serif;
					    color: $c_white;
					    text-transform: uppercase;
					    display: inline-block;
					    padding: 0px;
					    border-left: 1px solid $c_white;
					    height: auto;
					    line-height: 43px;
					    transition: 0.4s;
					    margin-right: -5px;
							a {
								color: $c_white;
								padding: 0 15px;
								height: 100%;
								display: block;
							}

							&:last-of-type {
								border-right: 1px solid $c_white;
							}
				    &:hover, &.active {
					    background-color: $c_white;
							a {
						    color:$c_black;
							}
					    cursor: pointer;
				    }
			    }


		    }

	    }

    }
}// preheader :end:


.main_navigation {
	display:none;
	@include breakpoint(phablet) {
        display:none;
    }

    @include breakpoint(tablet) {
        display:block;
    }

    @include breakpoint(laptop) {
        display:block;
    }

	background: #f1f1f1;

	.main_nav_wrap {
		    height: 60px;
		    margin: 0 auto;
		    max-width: 1200px;
		    padding: 0 10px;
				display: flex;
		.logo {
			// float:left;
			width: 15%;

			.logo_img {
				    width: 140px;
				    padding-top: 10px;
			 	}
			}

		.navigation {
			// float:left;
			width: 85%;
			margin-top: 0px;
			height: 100%;
			.nav_wrap {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				height: 100%;
				.menu_item {
					font-weight: 400;
					font-size:15px;
					font-family: 'Abolition', sans-serif;
					color: $c_black;
					text-transform: uppercase;
					padding: 0px;
					line-height: 43px;
					transition: 0.4s;
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					height: 100%;
					a {
						color: $c_black;
						font-weight: 300;
						font-family: Abolition, sans-serif;
						font-size: 20px;
						letter-spacing: 0px;
						padding-bottom: 0px;
						padding: 0px 15px;
						display: block;
					}
					&:hover,
					&.active {
						    background-color: #cccccc;
						    cursor: pointer;
					    }

				}

				.menu_btn {

						a {
							color: white !important;
							background-color: $c_red;
							border-radius: 30px;
							font-weight: 300;
							font-family: Abolition, sans-serif;
							font-size:20px;
							letter-spacing: 0px;
							padding: 0 30px 0px;
							&:hover {
									background-color: $c_red_dark;
									cursor: pointer;
								}
						}
						&:hover {
							background: transparent;

						}

				}
			}
		}
	}



} //main_navigation :end:


#sticky_navigation {
	position: fixed;
	top: -100px;
	left: 0;
	width: 100%;
	background-color: white;
	z-index: 1000;
	transition: 300ms ease all;
	.logo {
		display: flex;
		align-items: center;
		align-content: center;
		.logo_img {
			width: 50px;
			padding-top: 0;

			a {
				display: block;
			}
		}
	}
}














/* #endregion ------ Header ------- */


/* region ------ top_section ------*/

.top_section {
  background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(https://riders.basketball/app/uploads/2019/08/top_section_bg.jpeg);
    @include breakpoint(laptop) {
        margin-top: -68px;
    }
    .inner_wrap {
		background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), black);
	    height: 100%;
	    position: relative;
			padding: 0;
			width: 100%;
			margin: auto;
				@media (min-width: 767px) {
					padding: 110px 25px;
					width: 100%;
					}

					.slider-box  {
						.minislides {
							display: none;
							@media (min-width: 767px) {
								display: block;
							}
						}
					}
        .bx-wrapper {
			//	max-width: 600px !important;
				width: 100%;
				margin: 0;
				.inner-slide {
					display: block;
					.slide_image {
						width: 100%;
					}
					.info_box {
						width: 100%;
					}
					@media (min-width: 767px) {
						display: flex;
						.slide_image {
							width: 50%;
						}
						.info_box {
							width: 50%;
						}
					}
				}
				.slide_image {
					width: 50%;
					height: 100%;
				}
				.info_box {
					width: 50%;
					padding: 5%;
					display: flex;
					justify-content: center;
					flex-direction: column;
					h2{
						color: $c_white;
						font-size: 2.5em;
					}
					.button.red {
						background: $c_red;
						width: 150px;
						text-align: center;
						padding: 7px 0;
						transition: 300ms;
						a {
							color: $c_white;
							text-transform: uppercase;
					    font-family: Abolition;
							font-size: 1.4em;
					    letter-spacing: 4px;
						}
						&:hover {
							background: $c_red_dark;
						}
					}
				}
			}
    }
} // top_section

.game_overview {
	padding-top: 50px;
	padding-bottom: 30px;
	.game_overview_wrap {
		margin: 0 auto;
		max-width: 13800px;
		@include breakpoint(laptop) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 10px;
		}
		.game_info_box.recap_box {
			display: none;
			@include breakpoint(laptop) {
				display: grid;
			}
		}
	    .game_info_box {
			margin-bottom: 20px;
			background-color:$c_white;
			@include breakpoint(laptop) {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 10px;
			}
			.date {
				height: 120px;
				width: 30%;
				float: left;
				display: flex;
				align-items: center;
				justify-content: center;
				text-transform: uppercase;
				background-color: $c_grey;
				.date_box {
					text-align: center;
				}
				@include breakpoint(tablet) {
					width: 20%;
				}
				@include breakpoint(laptop) {
					width: unset;
					height: auto;
				}
			}
			.match_info {
				float: left;
				width: 70%;
				padding: 10px;
				@include breakpoint(laptop) {
					width: unset;
					float: none;
					grid-column: span 2 / auto;
				}
				.status {
					text-transform: uppercase;
					font-weight: 300;
					font-size: 12px;
				}
				.players {
				font-weight: 900;
					@include breakpoint(laptop) {
						font-size:0.8em;
					}
				}
				.time_location {
					@include breakpoint(laptop) {
						font-size:0.8em;
					}
				}
			}
			.clear {
				@include breakpoint(laptop) {
					display:none;
				}
			}


			.ticket_section {
				a{
					text-decoration: none;
				}
				.recap_btn {
					text-align: center;
					font-size: 2em;
					text-transform: uppercase;
					color: $c_white;
					background-color: #BCBCBC;
					font-family: 'Abolition', sans-serif;
					transition: 0.4s;
					a {
						font-family: 'Abolition', sans-serif;
						color: $c_white;
						font-size: 1.4em;
					}

					&:hover {
						background-color:#666666;
					}


				@include breakpoint(laptop) {
				height: 98px;
				font-size: 0.8em;
				display: flex;
				align-items: center;
				justify-content: center;
				}


				}

				.calendar_btn {
				text-align: center;
				text-transform: uppercase;
				font-size: 12px;
				padding: 5px;
				color:$c_red;

				a {
				text-decoration: none;
				color:$c_red;
				}
				@include breakpoint(laptop) {

				font-size: 0.5em;

				}
				}
			}


			    }

			    .two_boxes {


				    @include breakpoint(laptop) {
						           grid-column: span 2 / auto;
						      }

					    .two_box_wrap {

						    	@include breakpoint(tablet) {
						               	display: grid;
									    grid-template-columns: repeat(2, 1fr);
									    grid-gap: 10px;
										}

						    	@include breakpoint(laptop) {
									    display: grid;
									    grid-template-columns: repeat(2, 1fr);
									    grid-gap: 10px;
									}


							    .game_info_box {
							    	@include breakpoint(laptop) {
									        display: grid;
										    grid-template-columns: repeat(4, 1fr);
										    grid-gap: 10px;
									}

									.date {
									  width: 30%;
									  height: 120px;

									  @include breakpoint(laptop) {
								                width: auto;
											    float: none;
											    height: auto;
								            }
									}

									.match_info {
										width: 70%;
										text-align:left;

										@include breakpoint(laptop) {
								                width: 100%;
											    float: none;
											    text-align: left;
								            }
									}


									.ticket_section {


									.ticket_btn {
										    text-align: center;
										    font-size: 2em;
										    text-transform: uppercase;
										    color: $c_white;
										    background-color: $c_red;
										    font-family: 'Abolition', sans-serif;
										    transition: 0.4s;

										    a {
											    text-decoration: none;
													color: $c_white;
													font-size: 1em;
													padding-bottom: 5px;
    display: block;
										    }

										    &:hover {
											    background-color: $c_red_dark;
										    }

										 @include breakpoint(laptop) {
											    height: 98px;
											    font-size: 0.8em;
											    display: flex;
											    align-items: center;
											    justify-content: center;
											}

									}

									.calendar_btn {
									    text-align: center;
									    text-transform: uppercase;
									    font-size: 12px;
									    padding: 5px;
									    color:$c_red;

									    a {
										    text-decoration: none;
										        color:$c_red;
									    }

									    @include breakpoint(laptop) {

										    font-size: 0.5em;

											}
										}


									}

							    }

							}

						} //two_boxes
			 	   }

				} //gameoverview


/* #endregion ------ game_overview ------- */

/* region ------ content_grid ------*/

.content_grid {
	padding: 0px 20px;
	@include breakpoint(tablet) {

	}

	@include breakpoint(laptop) {
		width: 100%;
		max-width: 1400px;
		margin: 60px auto;
	}

	.grid_wrap {
		@include breakpoint(laptop) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
		}

		@include breakpoint(desktop) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 20px;
			padding: 100px 0px;
		}
		.grid_item {

				overflow: hidden;
		        position: relative;
		         a{
			         text-decoration: none;
		         }


			.listings_box {


				.single_listing {
				    text-align: left;
				    font-size: 1.5em;
				    text-transform: uppercase;
				    color: #32373c;
				    background-color: #F8F7F7;
				    font-family: 'Abolition', sans-serif;
				    margin-bottom: 15px;
				    padding: 20px 50px;
				    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
						-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
						box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
						&:last-of-type {
							margin-bottom: 0;
						}
				    .listing_button {
					   @include ghost_button;
					   transition: 0.4s;
					   font-size: 0.8em;
						 a {
							 color: $c_red;
						 }
					    &:hover {
						    background-color: $c_red;
								a {
							    color: $c_white;
								}
							}
					}

				}

				.single_listing.grey_listing {
				    background-color: #E1E1E1 !important;
				}

				.single_listing.highlight_listing {
				    color: $c_white !important;
				    background-color: $c_red !important;
				}

				.single_listing.full_list_btn {
					    padding: 0 !important;
					    background-color: transparent !important;
					}
			}

			.advert_box {
			    background-color: whitesmoke;
			    text-align: center;
			    margin: 30px 0px;
			    background-size: cover;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-image: url(https://riders.basketball/app/uploads/2019/08/advert_bg.jpeg);
			    transform: scale(1);
				transition: 0.5s;
				height: 100%;

				    &:hover {
					        transform: scale(1.2);
				    }



							@include breakpoint(laptop) {
								    float: none;
								    width: 100%;
								     margin: 0px 0px !important;
								}


			    .fade_bg {
				    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), black);
				    height: 100%;
				    position: relative;
				    padding: 100px 0px;
						background-size: cover;
				    .promotion {
							text-align: center;
							font-size: 2.3em;
							text-transform: uppercase;
							color: $c_white;
							font-family: 'Abolition', sans-serif;
							padding: 60px 60px;
							max-width: 400px;
							margin: 0 auto;
						}
				}
			}

		} // grid_items



	}

}

/* #endregion ------ content_grid ------- */

/* region ------ fan_store ------*/

.fan_store {
	margin-bottom: 70px;
	.fan_store_wrap {
    padding: 10px;
		.sub-heading {
			@include sub-heading;
	    border-bottom: 4px solid $c_red;
	    width: 150px;
	    margin: 0 auto;
		}
		.store_products {
			max-width:1200px;
			margin: 0 auto;
			padding: 10px;

					@include breakpoint(laptop) {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 10px;
				    margin-bottom: 50px;
			}


		.riders_product {
			margin-bottom: 30px;
			    margin: 0 auto;
				max-width: 400px;

			.product_image {
				    background-color: #F8F7F7;
				    margin-top: 50px;
				    padding: 20px;
				    border: 1px solid #cccccc;
						position: relative;
						.sale-box {
						    position: absolute;
						    top: 10px;
						    right: 10px;
						    z-index: 1000;
						    background-color: $c_red;
								padding: 14px 24px;
								h4 {
									margin: 0;
							    padding: 0;
									font-family: Abolition;
									font-size: 2em;
									color: $c_white;
								}
						}
				}

				.product_name_price {
				    text-align: center;
				    font-size: 1.5em;
				    text-transform: uppercase;
				    color: #000000;
				    padding: 20px 0px;
				    font-family: 'Abolition', sans-serif;
				}

				.buynow_button,
				.soldout_button {
				    text-align: center;
				    font-size: 1.5em;
				    padding: 8px;
				    color: $c_white;
				    background-color: $c_red;
				    text-transform: uppercase;
				    font-family: 'Abolition', sans-serif;
				    transition: 0.4s;
						width: 50%;
						margin: auto;
				    &:hover {
					    background-color: $c_red_dark;
				    }
				}
				.soldout_button {
					background: #A7A7A7;
					&:hover {
						background-color: #A7A7A7;
					}
				}


			}

		}

		.riders_product.dsk {
			    display: none;
			    					@include breakpoint(laptop) {
								display: block;
						}
			}


		.full_store_link {
			margin-top: 30px !important;
			 margin: 0 auto;
				max-width: 400px;
				    transition: 0.4s;
			@include ghost_button;

					    &:hover {
						    background-color: $c_red;
						    color: $c_white;
					    }
		}

	}


}


/* #endregion ------ fan_store ------- */



/* region ------ roster ------*/

.roster_section	{
	    padding: 10px;
	    								    width: 100%;
								    max-width: 1400px;
								    margin: 0 auto;

	    		.sub-heading {
			@include sub-heading;
			    border-bottom: 4px solid $c_red;
			    width: 150px;
			    margin: 0 auto;
		}

	.roster_wrap {
		padding: 10px;
		    margin-bottom: 50px;
.roster_wrapper_image {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	.roster_image {
		width: 80%;
		height: 90%;
		margin-top: 10%;
		background-position: center;
		background-size: cover;
	}
}


		@include breakpoint(laptop) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 10px;
				    margin-bottom: 50px;
			}



		.roster_listing {
				padding: 20px;
				margin-top: 30px;
                height: 500px;
                overflow: auto;
                text-align:justify;

                @include breakpoint(tablet) {
								height: 650px;
								}

			.roster_listing_top {
			    border-left: 4px solid $c_red;
		 -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
			-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);


			    .player_pic.female_player {
    background-image: url(https://riders.basketball/app/uploads/2019/09/woman_player.jpg) !important;
}

			 .player_pic {
				height: 200px;
				background-color: whitesmoke;
				background-size: cover;
			    background-repeat: no-repeat;
			    background-position: center center;
			    background-image: url(https://riders.basketball/app/uploads/2019/08/rob_pat_pic.jpg);

			    			@include breakpoint(tablet) {
								height: 300px;
								}
				}

			    .player_info {
				    padding: 20px 10px;
				    background-color: $c_tint;

				    .player_name {
					    text-transform: capitalize;
					    font-weight: 800;
				    }

				    .player_links {
					  a {
						text-transform: uppercase;
					    font-family: 'Abolition', sans-serif;
					    color: $c_red;
					    text-decoration: none;
					    }

				    }

				}
			 } //top_listing

			 .roster_listing_item {
				 background-color: $c_tint;
				 padding: 15px;
				 margin: 20px 0px;
				 -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
				-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
					box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
					display: flex;
					align-items: flex-end;
					border-left: 4px solid transparent;

					&.active{
						border-left: 4px solid $c_red;
					}
					&:hover{
						border-left: 4px solid $c_red;
					}
				.player_info {
					width: 40%;
					 .player_name {
					    text-transform: capitalize;
					    font-weight: 800;
							margin: 0;
					    white-space: nowrap;
				    }
						p {
							margin: 0;
						}
				}
				.player_links {
					a {
						color: $c_red;
					}
				}
			 }

		}

	}

			.roster_button {
						margin-top: 30px;
						width: 350px;
						max-width: 90%;
			    	margin: 0 auto;
		        transition: 0.4s;

							a {
								color: $c_red;
							}
			@include ghost_button;

					    &:hover {
						    background-color: $c_red;
								a {
									color: $c_white;
								}
					    }
		}
}

/* #endregion ------ roster ------- */


/* region ------ riders_footer ------*/

.riders_footer {


/* region ------ brands ------*/

.brands_section {
	background-color:$c_tint;

	.brands_wrap {
		    padding: 60px 20px;

		.brand_grid {
			display: grid;
		    grid-template-columns: repeat(2, 1fr);
		    grid-gap: 20px;

		        margin: 0 auto;
				width: 100%;
				max-width: 1400px;

		    @include breakpoint(tablet) {
					    display: grid;
					    grid-template-columns: repeat(4, 1fr);
					    grid-gap: 20px;
				}

			.brand_item {
				    height: 80px;
						a{
							height: 100%;
							width: 100%;
						display: flex;
				    align-items: center;
				    justify-content: center;
					}
				   img {
						    width: 70%;
								transition: 300ms;
								opacity: 0.7;
								filter: grayscale(100%);
								&:hover {
									opacity: 1;
									filter: grayscale(0%);
								}
						    @include breakpoint(laptop) {
							    width: 50%;
							}
						}
			}
		}
	}
}


/* #endregion ------ brands ------- */


/* region ------ footer menu section ------*/


.footer_menu {
	    padding: 30px 10px;
		background-color: #000000;
		    margin-bottom: 50px;

		.footer_menu_wrap {
		    margin: 0 auto;
		    padding: 20px;
		        max-width: 1400px;

					.large_ad_section {
						.large_ad_wrap {

					 @include breakpoint(laptop) {
					        display: grid;
						    grid-template-columns: repeat(2, 1fr);
						    grid-gap: 10px;
					}


							.advert_box {
								margin-bottom: 10px;
							}
						}
					} //large_ad

					.footer_nav_links {
						.footer_links_wrap {
							    margin-top: 30px;

							    @include breakpoint(laptop) {
								        display: flex;
										    justify-content: center;
												align-items: center;
												align-content: center;
									}


							.nav_links {
								display: grid;
							    grid-template-columns: repeat(2, 1fr);
							    grid-gap: 10px;

							    @include breakpoint(tablet) {
								        display: grid;
									    grid-template-columns: repeat(8, 1fr);
									    grid-gap: 10px;
									}

								@include breakpoint(laptop) {
								            grid-column: span 3 / auto;
									}

								.footer_link {
										text-transform: uppercase;
								    font-family: 'Abolition', sans-serif;
								    color: $c_white;
								    text-decoration: none;
								    text-align: center;
								    font-size: 1.3em;
										a {
											color: $c_white;
											&:hover {
												color: $c_red;
											}
										}
								    @include breakpoint(tablet) {
								       font-size: 20px;
									}
								}
							}


							.footer_social {
								margin: 0 auto;
								width: 150px;
								padding-top: 20px;

									@include breakpoint(laptop) {
								            padding-top: 0px;
														margin: 0;
									}

								.footer_social_wrap {
									display: grid;
								    grid-template-columns: repeat(4, 1fr);
								    grid-gap: 0px;

									.footer_social_icon {
											height: 30px;
									    width: 30px;
									    border: 1px solid $c_white;
									    display: inline-block;
									    margin: 0 auto;
											transition: 300ms ease all;
											a {
												display: flex;
												justify-content: center;
												align-items: center;
												align-content: center;
												height: 100%;
												transition: 300ms ease all;
												svg {
												transition: 300ms ease all;
													width: 70%;
													* {
													transition: 300ms ease all;
												}
												}
											}
											&:hover {
												border: 1px solid $c_red;
												a {
													svg {
														* {
															fill: $c_red !important;
													}
												}
											}
									}
								}
							}
						}
						}
					} //footer_nav_links

		}//footer_menu_wrap

}// footer_menu




/* #endregion ------ footer menu section ------- */

/* region ------ footer menu section ------*/


.footer_companies_strip {
	.footer_companies_strip_wrap {

		.posters {
			padding: 10px;
			    max-width: 1200px;
				margin: 0 auto;

			.posters_wrap {

					@include breakpoint(tablet) {
						margin: 0 auto;
						width: 400px;
					}

					@include breakpoint(laptop) {
						    width: 100%;
						    display: grid;
						    grid-template-columns: repeat(3, 1fr);
						    grid-gap: 10px;
					}
			}
		}

		.company_logos {
			margin-top: 50px;

				@include breakpoint(laptop) {
						margin-top: 0px;
					}

			.company_logos_wrap {
				    padding: 20px 20px;

				.company_logo_grid {
					display: grid;
				    grid-template-columns: repeat(2, 1fr);
				    grid-gap: 20px;

				    @include breakpoint(tablet) {
						display: grid;
					    grid-template-columns: repeat(4, 1fr);
					    grid-gap: 20px;
					}

					@include breakpoint(laptop) {
						display: grid;
					    grid-template-columns: repeat(7, 1fr);
					    grid-gap: 20px;
					    margin: 0 auto;
					    max-width: 1200px;
					}

					.company_logo_img {
						    height: 120px;
								a {
									display: flex;
							    align-items: center;
							    justify-content: center;
									width: 100%;
									height: 100%;
								}
						   img {
								    width: 80%;

								}
					}
				}
			}
		}


	}

}


/* #endregion ------ footer menu section ------- */


/* region ------ footer_credits ------*/


.footer_credits {
	padding: 10px;
	background-color: $c_red;
	color:$c_white;
	margin-top: 20px;
	text-align: center;
	.footer_credits_wrap {
		    margin: 0 auto;
    width: 100%;
    max-width: 880px;
		.credit_txt {
			    font-size: 13px;
				display: inline-block;
				text-align: center;
				a {
					color: $c_white;
					&:hover{
						text-decoration: underline;
					}
				}
		}
	}

}


/* #endregion ------ footer_credits ------- */


} //riders_footer

/* #endregion ------ riders_footer ------- */



/* region ------ introduction section------*/


	.introduction_section {
		background-color: $c_red;
		background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
	    background-image: url(https://riders.basketball/app/uploads/2019/08/advert_bg.jpeg);
	    margin-top: -69px;


		.introduction_section_wrap  {
/*
	    	    display: flex;
			    align-items: center;
			    justify-content: center;
*/
					margin-top: 70px;
			    padding: 25px;
					position: relative;
					@media (min-width: 767px) {
						padding: 50px;
					}
					.background {
						background-color: $c_red;
						opacity: 0.5;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					&.no_bck {
						.background {
							background-color: transparent;
							opacity: 0.5;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
						background-color: transparent;
					}
	    	h1.page_title {
					position: relative;
					z-index: 3;
				    text-align: center !important;
				    color:$c_white;
				    font-family: Abolition;
				    font-weight: 500;
				    font-size: 4.5em;
				    padding: 0px;
				    margin: 0 auto;
						@media (min-width: 767px) {
							padding: 50px 40px;
						}
				}
		}



	}

/* #endregion ------ introduction section ------- */

.single-roster {
	.introduction_section {
		.introduction_section_wrap {
			background-color: #00000070;
		}
	}
}


/* region ------ player introduction section ------*/


	.player_introduction_section {
			background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
	    background-image: url(https://riders.basketball/app/uploads/2019/09/profile_bg.jpg);
	    margin-top: -69px;


		.player_introduction_section_wrap  {
			    padding: 150px 50px;
			    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), black);
			    height: 100%;
			    position: relative;

	    	h1.page_title {
				    text-align: center !important;
				    color:$c_white;
				    font-family: Abolition;
				    font-weight: 500;
				    font-size: 4.5em;
				        margin: 0 auto;
				}
		}



	}

/* #endregion ------ player introduction section ------- */


/* region ------ season ticket intro ------*/


	.season_ticket_intro_section {
		background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
	    background-image: url(https://riders.basketball/app/uploads/2019/09/season_ticket_bg.png);
	    margin-top: -69px;
	    clip-path: polygon(0 0, 100% 0, 100% 88%, 0 94%);


		.season_ticket_intro_section_section_wrap  {
			    padding: 250px 50px;
			    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), black);
			    height: 100%;
			    position: relative;

	    	h1.page_title {
				    text-align: center !important;
				    color:$c_white;
				    font-family: "Mystique", Abolition;
				    font-weight: 500;
		        margin: 0 auto;
						font-size: 3em;

						@include breakpoint(tablet) {
							font-size: 4.5em;

						}
			}


				 p {
				    text-align: center;
				    color: $c_white;
				    max-width: 600px;
				    margin: 0 auto;
				    margin-top: 50px;
				}
		}



	}

/* #endregion ------ season ticket intro ------- */



/* region ------ subpage_nav ------*/


.subpage_nav {
	background-color: #F5F5F5;

	.subpage_nav_wrap {

		ul {
		  list-style-type: none;
		  margin: 0;
		  text-align: center;
			padding: 0;

		  	li {
					display: inline-block;
					font-weight: 400;
					font-size:1.4em;
					font-family: 'Abolition', sans-serif;
					color: $c_black;
					text-transform: uppercase;
					display: inline-block;
					padding: 0px 15px;
					height: auto;
					line-height: 43px;
					transition: 0.4s;
					width: 100%;
					border-bottom: 1px solid #ddd;
					@media (min-width: 767px) {
						width: unset;
						width: auto;
						border-bottom: 0px;
					}
					a {
							color: $c_black;
					}
					&.active, &:hover {
				    background-color: #cccccc;
				    cursor: pointer;
			    }
			  	.selected {
				  	background-color: #cccccc;
			  	}
		  	}
		  }
	}

}


/* #endregion ------ subpage_nav ------- */

/* region ------ game_listing ------*/


	.game_listing {

		margin-bottom: 100px;
				  h2.game_listing_title {
						text-align: center !important;
						color:#000000;
						font-family: Abolition;
						font-weight: 500;
						font-size: 2em;
						margin: 80px 0px 25px;
					}


		.month_listing {

			.game_listing_wrap  {


					    @include breakpoint(tablet) {
								display: grid;
							    grid-template-columns: repeat(2, 1fr);
							    grid-gap: 20px;
							}

					   					    @include breakpoint(laptop) {
								display: grid;
							    grid-template-columns: repeat(3, 1fr);
							    grid-gap: 20px;
							}

				        margin: 0 auto;
					    padding: 20px;
					    max-width: 1400px;
					    width: 100%;

					    .game_info_container.highlighted_game {
						    border: 4px solid $c_red !important;

						    					.recap_btn {
									//	display: none !important;
									}
						}

						.game_info_container.special_game {
									background-size: cover;
								    background-repeat: no-repeat;
								    background-position: center;
								   // background-image: url(https://riders.basketball/app/uploads/2019/09/NEONAndy.jpg);

								    .box_label {
									        padding: 10px;
										    background-color: #36CD86 !important;
										    text-transform: uppercase;
										    font-family: Abolition;
										    text-align: center;
										    max-width: 100px;
										    margin-top: 20px;
										    float: right;
									}
									.info_text {
										* {
											color: $c_white;
										}
									}
							.fade_bg {
							    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), black);
							    height: 100%;
							    position: relative;

							    .team_names {
								    color: #36CD86;
								}

								.game_type,
								.game_dates {

									color:$c_white;
									}

									.recap_btn {
									//	display: none !important;
									}

														.ghost_btn {
														    color: $c_white !important;
														    border: 2px solid $c_white !important;
															    &:hover {
																    background-color:#666666;
																    color:$c_white;
															    }

														    }

								.ticket_btn {
									 border: 2px solid #36CD86 !important;
									background-color: #36CD86 !important;


									&:hover {
											 background-color:#248e5c !important;
										}

								}

							}
						}

					    .game_info_container {
								width: 100%;
							    margin-bottom: 15px !important;
							    background-color: #f7f7f7;
							    border:1px solid #ccc;
							    margin: 0 auto;
									transition: 300ms all ease;
									&:hover{
										box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.31);
									}
									&.disabled {
										background-color: #E8E8E8;
										border: 1px solid #8E8E8E;
										.logo_section, .info_section {
											//opacity: 0.5;
										}
									}
							    .game_info_wrap {
									    padding:30px;

									    .logo_section {

										   		 display: grid;
											    grid-template-columns: repeat(4, 1fr);
											    grid-gap: 10px;

											    .logo_box {
													    width: 80px;
													    height: 80px;
													    margin-bottom: 10px;
															img {
																&.disabled {
																	opacity: 0.3;
																}
															}
													}

										}

										.info_section {

										    .info_text {

											    line-height: 25px;

											    .team_names {
														font-size: 18px;
														font-weight: 700;
												}

												.game_type {
												    text-transform: uppercase;
														font-size: 12px;
														font-weight: 300;
												}

												.game_dates {
													font-size: 14px;
													font-weight: 500;
												}
												.time_location {
													display: block;
												}
												.scores {
												    font-size: 20px;
														font-weight: 500;
												    color:$c_red;
												}
										    }

										}

										.buttons_section {
										    margin-top: 30px;

										    @include breakpoint(laptop) {
												display: flex;
												flex-wrap: wrap;
											}

										    		.recap_btn {
															@include ghost_button;
														    background-color: #BCBCBC;
														    border: 2px solid #BCBCBC;
														    transition: 0.4s;
																margin: 0 5px;
																margin-bottom: 10px;
																min-width: 90px;
																&:first-of-type {
																	margin: 0 5px 10px 0;
																}
																a{
																	color: $c_white;
																}

															    &:hover {
																    background-color:#666666;
																    color: $c_white !important;
															    }

														    }

													.ghost_btn {
														    @include ghost_button;
														    	transition: 0.4s;												       											color: #BCBCBC !important;
															   	border: 2px solid #BCBCBC;
															   	transition: 0.4s;
															   	margin-bottom: 10px;


															    &:hover {
																    background-color:#666666;
																    color:$c_white !important;
															    }

														    }

													.ghost_btn.highlighed_btn {
														       	color: #e4022e !important;
															   	border: 2px solid #e4022e;
															   	width: auto;
															   	transition: 0.4s;
															   	margin-bottom: 10px;


															    &:hover {
																    background-color:#e4022e;
																    color:$c_white !important;
															    }


														}


													.ticket_btn {
														    @include ghost_button;
														    background-color: $c_red;
														    color: $c_white;
														    transition: 0.4s;
														    margin-bottom: 10px;


															    &:hover {
																    background-color:$c_red_dark;
																    color: $c_white !important;
															    }

														    }



										}


									}


							    @include breakpoint(tablet) {
									margin-bottom: 0px !important;
								}
						}//game_container
				}

		}

	}

/* #endregion ------ game_listing ------- */


/* region ------ Roster Page List ------*/

.roster_page_listing {
	.roster_page_listing_wrap {
				margin: 0 auto;
				max-width: 1200px;

		.player_container {
			max-width: 1000px;
			margin: 30px auto;

			.player_container_wrap {


				.player_info {
		            border: 1px solid #cccccc;
								transition: all 300ms ease;
							    width: 100%;
									&:hover {
											box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
									}

						@include breakpoint(tablet) {
									width: 94%;
									float: left;
								}

					    .player_image {
						    height: 200px;
						    width: 50%;
						    background-size: cover;
						    background-repeat: no-repeat;
						    background-position: top center;
 							float:left;

						}

						.player_details {
							padding: 30px;
							float:left;
							width: 50%;


							.players_name {
							    font-size: 1.5em;
							    font-weight: 700;
							}

							.players_position {
							    text-transform: uppercase;
							    color: #8d8d8d;
									font-weight: 300;
									letter-spacing: 1.5px;
							}

							.profile_btn {
								@include button-style;
								max-width: 120px;
								margin-top: 20px;
								padding: 0;
								font-size: 1.2em;
								a {
									color: $c_white;
									display: block;
									padding: 6px 0;

								}
								&:hover {
									 background-color:$c_red_dark;
								}
							}
						}

				}

				.social_links {
					    width: 100%;
					    padding: 5px;
					    margin-top: 10px;
display: none;


					    @include breakpoint(tablet) {
                display: block;
								        width: 5%;
							    height: 200px;
							    margin-top: 0px;
							    float: right;
								}

						.social_links_wrap {
							height: 100%;
							justify-content: center;
							display: flex;
							flex-direction: column;
							.social_link_box {
							    min-width: 35px;
							    min-height: 35px;
							    border: 0.7px solid #8E8E8E;
							    margin-right: 10px;
							    display: inline-block;
									transition: all ease 300ms;
									&:hover {
										opacity: 0.8;
									}
									a {
										width: 100%;
										height: 100%;
										display: flex;
										justify-content: center;
										align-items: center;
										align-content: center;
										svg {
											width: 25px;
											height: 25px;
											fill: #8d8d8d;
											transition: 300ms all ease;
											*, .st0 {
											fill: #8d8d8d;
											transition: 300ms all ease;
											}
											&:hover {
												fill: $c_red;
												*, .st0 {
													fill: $c_red;
												}
											}
										}
									}
							 @include breakpoint(tablet) {
								 margin-bottom:5px;
								     display: block;
								      margin-left: 0px;
								}

							}
						}

					}
			}
		}
	}
}


/* #endregion ------ Roster Page List ------- */


/* region ------ single game listing ------*/


.side_bar_wrap {

	.game_info_container.highlighted_game {
    border: 4px solid $c_red !important;
        margin-bottom: 50px;

		    .game_info_wrap {
		    padding: 30px;

		    .game_info_container {
			    width: 100%;
			    margin-bottom: 15px !important;
			    background-color: #f7f7f7;
			}

		    .logo_section {
			    display: grid;
			    grid-template-columns: repeat(4, 1fr);
			    grid-gap: 10px;
			}

		    .info_section {

			    .info_text {
				        line-height: 32px;

					.team_names {
					    font-size: 1.2em;
					    font-weight: 700;
					}

				.game_type {
					    text-transform: uppercase;
					}

			    }

		    }// info_section


		    						.buttons_section {
										    margin-top: 30px;

										    @include breakpoint(laptop) {
												display: grid;
												grid-template-columns: repeat(4, 1fr);
												grid-gap: 10px;
											}

										    		.recap_btn {
															@include ghost_button;
														    color: $c_white;
														    background-color: #BCBCBC;
														    border: 2px solid #BCBCBC;
														    transition: 0.4s;
																margin: 0;
																margin-bottom: 10px;
																cursor: pointer;
																a {
																	color: $c_white;
																}

															    &:hover {
																    background-color:#666666;
																		a {
																			color: $c_white !important;
																		}
															    }

														    }

													.ghost_btn {
														    @include ghost_button;
														    	transition: 0.4s;
																	color: #BCBCBC !important;
															   	border: 2px solid #BCBCBC;
																	margin: 0;
															   	transition: 0.4s;
															   	margin-bottom: 10px;
																	cursor: pointer;


															    &:hover {
																    background-color:#666666;
																    color:$c_white !important;
															    }

														    }

													.ghost_btn.highlighed_btn {
														       	color: #e4022e !important;
															   	border: 2px solid #e4022e;
															   	width: auto;
															   	transition: 0.4s;
																	margin: 0;
															   	margin-bottom: 10px;
																	cursor: pointer;


															    &:hover {
																    background-color:#e4022e;
																    color:$c_white !important;
															    }


														}


													.ticket_btn {
														    @include ghost_button;
														    background-color: $c_red;
														    color: $c_white;
														    transition: 0.4s;
																margin: 0;
														    margin-bottom: 10px;
																cursor: pointer;

															    &:hover {
																    background-color:$c_red_dark;
																    color: $c_white !important;
															    }

														    }



										} //buttons_section

		}


	}

}


/* #endregion ------ single game listing ------- */



/* region ------ game_night_layout ------*/


.two_col_layout {
    margin-top:50px;
    width: 100%;

    .two_col_layout_wrap {
	        padding: 20px;
	        margin: 0 auto;
	        max-width: 1400px;

	        .two_col_layout_content {

		        h2.sub_heading {

				    margin: 30px 0px;

		        }

		          @include breakpoint(laptop) {
								float:left;
						        width:60%;
								margin-right: 5%;
						}
	        }

	        .two_col_sidebar {

		        @include breakpoint(laptop) {
							float:left;
							width:35%;
						}
			}
    }


}


/* #endregion ------ game_night_layout------- */


/* region ------ sign_up_box ------*/


			.sign_up_box {
		        padding: 40px 30px;
						height: 100%;
				    @include breakpoint(tablet) {
								    border: 1px solid;
								    margin-bottom: 50px;
								    margin-top: 50px;
							}


					@include breakpoint(laptop) {
								    margin-bottom: 0px;
								    margin-top: 0px;
							}



				.sign_up_wrap {

					.sub_title {
							text-align: center;
							font-size: 2em;
							text-transform: uppercase;
							color: $c_black;
							font-family: 'Abolition', sans-serif;

					}

					.benefits_section {
						.benefit_wrap {

								@include breakpoint(tablet) {
								display: grid;
							    grid-template-columns: repeat(3, 1fr);
							    grid-gap: 10px;
								}


							.benefit_item {
							    text-align: center;
							    margin: 30px 0px;

							    .signup_icon {
								    width: 80px;
								    height: 80px;
								    display: flex;
								    align-items: center;
								    justify-content: center;
								    margin: 0 auto;
								}

								.signup_text {
								    margin-top: 10px;
								        font-size: 12px;
								}
							}
						}
					}

					.signup_form {
					   #mc_embed_signup_scroll {
							 display: flex;
							 #mce-EMAIL {
								 height: 35px;
								 border-radius: 0;
								 width: 100%;
							 }
							 #mce-responses {
								 display: none;
							 }
							 .mc-field-group	{
								 width: 75%;
							 }
							 .clear {
								 width: 100%;
								 input {
									 width: 100%;
									 min-height: 35px;
									 border-radius: 0;
									 color: $c_white;
									 background-color: $c_red;
									 border: 0;
									 font-family: Abolition;
									 font-size: 1.5em;
									 padding: 11px;
									 cursor: pointer;
									 transition: 300ms;
									 &:hover {
										 background: $c_red_dark;
									 }
								 }
							 }

						 }
					}
				}
			}


/* #endregion ------ sign_up_box ------- */


/* region ------ blog post list ------*/

.blog_post_list {

// 	    background-color: whitesmoke;

	.blog_post_list_wrap {
		    padding: 20px;
		    margin: 0 auto;
		    max-width: 1400px;

		    @include breakpoint(tablet) {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 20px;
				}

			@include breakpoint(desktop) {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 20px;
				}
		}

}


.blog_post_item {
			    background-color: #DEDEDE;
			    margin: 30px 0;
					transition: all ease 300ms;
					&:hover {
						box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
					}
						    @include breakpoint(tablet) {
					margin: 0;
				}


			    a.blog_img_link {

			.blog_post_thumb_wrap {
			    overflow: hidden;
			    position: relative;

				    .blog_post_thumb {
					    background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;
						background-image: url(https://riders.basketball/app/uploads/2019/08/blog_image_two.jpg);
						transform: scale(1);
						transition: 0.5s;
						height:250px;

					    &:hover {
						        transform: scale(1.2);
					    }

					 }

				 }

			}

				.blog_post_info {
					    padding: 10px;
							height: calc(100% - 210px);
			@include breakpoint(laptop) {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 0px;
					padding:0px;
				}

				.info_wrap{
								@include breakpoint(laptop) {
								grid-column: span 3 / auto;
								padding: 10px;
							}

						.post_title {
						    font-weight: 600;
								padding-left: 20px;
						}

						.post_excerpt {
						    font-size: 0.9em;
								padding-left: 20px;
						}
				}


						.blog_btn {



							    .read_more_btn {
									@include button-style;
									margin-top: 20px;
 									display: flex;
									justify-content: center;
									&:hover {
										background-color:$c_red_dark;

									}

									@include breakpoint(laptop) {
										margin-top: 0px;
										    font-size: 1em;
										        padding: 40px 19px;
									}

								}



						}


					}
			}//blog_post_item



/* #endregion ------ blog post list  ------- */


/* region ------ blog post article ------*/


.blog_post {
	    padding: 20px;

	.blog_post_wrap {
	    margin: 0 auto;
	    max-width: 1400px;

    	.blog_article {
	    	margin-bottom: 50px;
	    							@include breakpoint(desktop) {
										     width: 62%;
											 float: left;
									}

	    	.featured_image {
				margin-bottom: 50px;
			}

			.post_date {
				@include sub-heading;
				text-align: left;
				color: $c_red;
				font-size: 16px;
				letter-spacing: 1px;
			}


			.post_title {
			    font-size: 2em;
			    text-transform: capitalize;
			    font-weight: 600;
			}

    	}

    	.blog_post_sidebar {
		        max-width: 500px;

		        	    	@include breakpoint(desktop) {
										    max-width: 500px;
											float: right;
											width: 30%;
									}

									.blog_post_item {
										margin-bottom: 30px;
									}
		}
	}
}


.gameoverview_blogpost {
    margin-bottom: 80px;

		    .game_overview {

			   .game_info_box {
			   		border: 1px solid #ccc;
				}
    }
}

/* #endregion ------ blog post article  ------- */


/* region ------ sponsor logo grid ------*/


.sponsors_list {

	.sponsor_list_wrap {
		    padding: 20px;
		    margin: 0 auto;
		    max-width: 1400px;


		.sponsor_list_grid {

			@include breakpoint(tablet) {
					display: grid;
				    grid-template-columns: repeat(2, 1fr);
				    grid-gap: 10px;
				}

			@include breakpoint(laptop) {
					display: grid;
				    grid-template-columns: repeat(4, 1fr);
				    grid-gap: 30px;
				}

						@include breakpoint(desktop) {
					display: grid;
				    grid-template-columns: repeat(5, 1fr);
				    grid-gap: 30px;
				}


			.sponsor_item {
				margin-bottom: 75px;
				padding-bottom: 56px;
				height: 100%;
				position: relative;
				.sponsor_logo {
					        display: flex;
						    align-items: center;
						    justify-content: center;
						    padding: 50px 30px;
						    width: 100%;
						    max-width: 300px;
						    height: 300px;
						    background-color: #F7F7F7;
						    border: 1px solid #cccccc;
						    margin: 0 auto;

				@include breakpoint(laptop) {
					height: 180px;
				}
					}

					.sponsor_name {
					    font-weight: 800;
					    margin: 20px 0px;
					    text-align: center;
							min-height: 48px;
					}
					.sponsor_paragraph {
						p {
							margin: 0;
						}
					}
					.sponsor_link {
						position: absolute;
						bottom: 0;
						width: 100%;
						@include ghost_button;
						transition: 0.4s;

								 &:hover {
									background-color:$c_red;
									color: $c_white !important;
								}
					}




			}

		}

	}

}


/* #endregion ------ sponsor logo grid   ------- */


/* region ------ player_stats ------*/


.player_stats {
	    margin: 80px 0px;
	.player_stats_wrap {

		margin: 0 auto;
		max-width: 1200px;

				display: grid;
			    grid-template-columns: repeat(2, 1fr);
			    grid-gap: 10px;

			@include breakpoint(tablet) {
				display: grid;
			    grid-template-columns: repeat(4, 1fr);
			    grid-gap: 10px;
			}


		.stat_item {
		    text-align: center;

		    .stat_figure {
				    font-size: 5em;
				    color:$c_red;
				    font-family: Abolition;


				}


			.stat_name {
				    font-size: 0.8em;
				    font-weight: 600;
				}


		}

	}

}


/* #endregion ------ player_stats  ------- */



/* region ------ player_info ------*/

.player_info {

    .player_info_wrap {
	    margin: 0 auto;
	    max-width: 1400px;

	    .player_photo {

		    img {

		        margin: 0 auto;
				display: block;
		    }

	    	@include breakpoint(laptop) {
					    width: 45%;
						float: left;
				}
			}

		.info_container {
			   	@include breakpoint(laptop) {
				    float: right;
				    width: 50%;

				    }

			.container_wrap {

				.player_breakdown {
				    margin-bottom: 40px;

					    .player_breakdown_wrap {

						    @include breakpoint(tablet) {
							    display: grid;
							    grid-template-columns: repeat(2, 1fr);
							    grid-gap: 10px;
						    }

						    .player_detail_item {

							        margin-bottom: 20px;

							    .detail_title {
								    font-size: 2em;
								    font-family: Abolition;
								}
						    }
					    }
				}
			}
		}


	}


}


/* #endregion ------ player_info  ------- */


/* region ------ player_media ------*/

.player_media {

	.player_media_wrap {
		    padding: 100px 20px;
		margin: 0 auto;
		max-width: 2000px;


		.underline{
			height: 5px;
			max-width: 150px;
			background-color: $c_red;
			margin: 0 auto;
			margin-bottom: 50px !important;
		}

		.video_highlights,
		.player_gallery {
			@include sub-heading;
			    margin: 0 auto;
		}


		.video_highlights {
			    margin-bottom: 100px !important;


			    @include breakpoint(laptop) {
						float: left;
						width: 45%;
    			}

			}


		.player_gallery {


				@include breakpoint(laptop) {
						float: left;
						width: 45%;
						margin-left: 3%;
    			}


			.gallery_wrap {

				@include breakpoint(tablet) {
					display: grid;
				    grid-template-columns: repeat(2, 1fr);
				    grid-gap: 20px;
				    max-width: 722px;
				    margin: 0 auto;
    			}


				.gallery_image {
						height: 240px;
					    width: 100%;
					    background-color: skyblue;
					    margin: 0 auto;
					    margin-bottom: 20px;
					    background-size: cover;
						background-repeat: no-repeat;
						background-position: top center;
						background-image: url(https://riders.basketball/app/uploads/2019/09/gallery_image.jpeg);

					    	@include breakpoint(tablet) {
								margin-bottom: 0px;
								width: 350px;
							}
					}
			}
		}

	}

}


/* #endregion ------ player_media  ------- */


/* region ------ two_col_text ------*/


.two_col_text {
	padding: 20px;
	margin: 0 auto;
    max-width: 1400px;
	.two_col_text_wrap {
		margin: 0 auto;
		max-width: 1200px;
		padding: 60px 0px;
		p {
			margin-top: 0;
		}
	@include breakpoint(tablet) {
		column-count: 2;
		column-gap: 60px;
		}
	}

	.page_btn {
		@include button-style;
	    margin: 0 auto;
	    display: inherit;
	    max-width: 300px;;
		transition: 0.4s;
		font-size: 1.3em;


			&:hover {
				background-color: $c_red_dark;
			}
	}

}


/* #endregion ------ two_col_text  ------- */


/* region ------ image text block one ------*/


.image_text_block_one {
	margin: 100px 0px;

	.bg_image {
		width: 100%;
	    height: 500px;
	    background-color: skyblue;
	    background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url(https://riders.basketball/app/uploads/2019/09/text_block_img.jpeg);
		clip-path: polygon(0 7%, 100% 0, 100% 88%, 0 94%);
		background-attachment: fixed;
	}

	.text_block {
		    margin: 0 auto;
    max-width: 1200px;
			.block_wrap {
		    padding: 40px;
		    color: $c_white;
		    background-color: $c_red;
		    margin: -150px 20px 20px 20px;
		    z-index: 1;
		    position: relative;

		    	@include breakpoint(laptop) {
					max-width: 600px;
					margin: -180px 20px 20px 20px;
			}

		}

	}

}


/* #endregion ------ image text block one   ------- */


/* region ------ pricing table ------*/


.pricing_table {
	padding: 20px;
	margin: 100px 0px;
	.pricing_table_wrap {
		margin: 0 auto;
		max-width: 1200px;
		.table_info {
			padding-top: 100px;
			    display: grid;
			    grid-template-columns: repeat(2, 1fr);
			    grid-gap: 10px;

			    @include breakpoint(laptop) {
								    display: grid;
			    grid-template-columns: repeat(3, 1fr);
			    grid-gap: 10px;
					}

			    .item.tab_header {
				    font-family: Abolition;
				    background-color: #000000 !important;
				    line-height: 60px;
				    font-size: 2em;
				    color: $c_white;
				}

			    .item {
				    font-family: Abolition;
				    background-color: #f1f1f1;
				    line-height: 60px;
				    font-size: 1.2em;
				    color: #555d66;
				    margin-bottom: 5px;
				    text-align: center;
				    text-transform: uppercase;
				}



					.item:nth-child(odd) {
						background-color: #e0e0e0;
					}


				.type.type_mob {

					@include breakpoint(laptop) {
					display: none;
					}

				}


			}
	}
}


/* #endregion ------ pricing table ------*/

/* region ------ two buttons ------*/

.two_buttons {
	margin: 100px 0px;
	.two_buttons_wrap {
			    margin: 0 auto;
			    width: 100%;
			    max-width: 610px;

			    			@include breakpoint(tablet) {
							   max-width: 610px;
						}

			.button_normal,
			.button_ghost {
				@include button-style;
				margin: 0 auto;
				display: inherit;
				max-width: 300px;;
				transition: 0.4s;
				margin-bottom: 5px;
				border: 1px solid $c_red;
				font-size: 1.3em;

				@include breakpoint(tablet) {
									float: left;
								width: 49%;
								margin-left: 1%;
						}


					&:hover {
						background-color: $c_red_dark;
					}
			}

					.button_ghost {
						color: $c_red;
						background-color: transparent;
						border: 1px solid $c_red;
										&:hover {
							color: $c_white;
						}

					}

		}


}

/* #endregion ------ two buttons ------*/


/* region ------ image_text_block_two ------*/

.image_text_block_two {
	margin: 100px 0px;

	.image_text_block_two_wrap {
		    padding: 10px;
		    margin: 0 auto;
    max-width: 1400px;

		.image_layout {

					@include breakpoint(laptop) {
							float: left;
							width: 55%;
						}
			}

		.text_block {
		    padding: 40px;
		    color: $c_white;
		 background-color: #32373cf5;

		    		@include breakpoint(laptop) {
							    float: left;
							    width: 50%;
							    position: relative;
							    z-index: 1;
							    margin-left: -81px;
							    margin-top: 50px;
						}

		}

	}

}

/* #endregion ------ image_text_block_two ------*/


/* region ------ player_header ------*/

.player_header {
	margin: 100px 0px;
			    		@include breakpoint(laptop) {
							  background-color: $c_red;
						}


	.player_header_wrap {
		    margin: 0 auto;
			max-width: 1200px;


			@include breakpoint(laptop) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 0px;
						}

			.image_cutout {
				position: relative;
							    		@include breakpoint(laptop) {
							  margin-bottom: 0px;
						}
						.image_side {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-size: cover;

						}
			}

		.text_block {
		    padding: 40px;
		    color: $c_white;
		    background-color: $c_red;;
		    margin-top: -8px;

		    				@include breakpoint(laptop) {
								      grid-row-start: 1;
								      margin-top: 0px;
								          padding-top: 50px;
						}

						@include breakpoint(desktop) {
								          padding-top: 100px;
						}


			h3 {
				font-family: Abolition;
				font-size: 2.2em;
			}
		}

	}

}



/* #endregion ------ player_header ------*/


/* region ------ Contact_us_page ------*/

.contact_us_page {

	   // margin-top: -70px;
		 display: flex;

		&.container_wrapper {
			max-width: 1000px;
    	margin: 30px auto;
			background-color: #F7F7F7;
			h1 {
				font-family: 'Abolition', sans-serif;
				font-weight: 500;
				font-size: 2.5em;
			}
		}
		&.bit_up {
			margin-top: -30px;
			margin-bottom: 100px;
		}
		.gform_fields  {
       display: block !important;
      @media (min-width: 767px) {
        display: flex !important;
				flex-direction: column;
      }
		}
		.contact_us_form_full {
      display: block;
      width: 100%;
      @media (min-width: 767px) {
        display: flex;
      }
			.other_side {
        width: 100%;
        @media (min-width: 767px) {
          width: 25%;
        }
				background-color: $c_red;
				color:  $c_white;
				padding: 5% 2.5%;
    		text-align: center;
			}
			.form_wrap {
        width: 100%;
        padding: 30px 50px;
        @media (min-width: 767px) {
          width: 75%;
          padding: 30px 50px;
        }

			}

		}
.gfield  {
	width: 100%;
  @media (min-width: 767px) {
    width: 50%;
  }
	input, textarea, .ginput_container, .gfield_select {
		width: 100% !important;
		background-color: transparent;
	}
}
	.contact_us_map {
 			width: 50%;
	}

	.contact_us_form {
 	    padding: 30px;
			width: 50%;
	    .form_wrap {
		    h1 {
				font-size: 4em;
				font-weight: 500;
				text-transform: uppercase;
				color: $c_black;
				font-family: 'Abolition', sans-serif;
				margin: 0;
				margin-bottom: 0.7rem;
		    }
				.form_text {
					max-width: 400px;
				}
	    }
	}


}

.footer_social_icon img {
	padding: 4px;
}



/* #endregion ------ Contact_us_page ------*/

.travel_button {
    width: 30%;
    text-align: center;
    background: #F5F5F5;
    border: 2px solid #707070;
		transition: all ease 300ms;
		margin: 15px 0;
		&:hover {
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
		}
		a {
	    color: #111;
			font-family: 'Abolition', sans-serif;
			font-size: 1.2em;
			display: block;
			padding: 10px;

		}
}

.validation_error {
	display: none;
}


.page-template-template-contactus {
	.gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
		background-color: transparent;
		margin-bottom: 0;
		margin-top: 0;
		border-top: 0;
		border-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	.gform_wrapper li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']), .gform_wrapper li.gfield_error textarea {
		border: 1px solid #ccc;
	}
	.gform_wrapper .field_description_below .gfield_description {
		padding-top: 0;
		color: $c_red;
	}
	.gform_wrapper .gfield_error .gfield_label {
		color: $c_red;
	}
}
.top_section .inner_wrap .bx-wrapper {
	.minislides {
	    position: absolute;
	    display: flex;
	    bottom: -50%;
			display: none;
			@media (min-width: 767px) {
				display: flex;
			}
			.mini-slide {
				width: 50%;
				margin: 0 10px;
				cursor: pointer;
				transition: 300ms all ease;
				&:hover {
					box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
				}
				.mini-slide_image {
					width: 100%;
				}
				.info_box {
					background: #F8F7F7;
					width: 100%;
					min-height: 100px;
					h5 {
						font-size: 14px;
						text-transform: none;
					}
				}
			}
	}
}

.slider-box {
	.bx-wrapper {
		.bx-viewport {
			overflow: visible !important;
		}
	}
}
.slider-box {
	    padding-bottom: 55px;
			width: 90%;
			@media (min-width: 767px) {
				width: 100%;
			}
}

#gform_wrapper_1 {
	.gfield_label {
		font-size: 1.3em;
	}

}

.bck-red-box {
	fill: $c_logo !important;
}



















.palyer-stats {
  padding: 25px 0;
  display: flex;
  justify-content: space-around;
  .stat {
    text-align: center;
    h2, p{
      text-align: center;
    }
  }
}

.player_info_wrapper {
  display: block;
  .player_image {
    width: 100%;
  }
  .player_info  {
    width: 90%;
    padding: 0 5%;
    .small_stats {
      display: flex;
      flex-wrap: wrap;
      .small_stat {
        width: 50%;
        margin-top: 10px;
        h3 {
          font-family: 'Abolition', sans-serif;
          margin: 0;
        }
        p{
          margin: 5px 0;
        }
      }
    }
  }
	@media (min-width: 767px) {
		display: flex;
		.player_info {
			width: 50%;
			}
			.player_info {
				width: 50%;
			}
		}
	}

.bx-wrapper {
  box-shadow: none;
    border: none;
    background: transparent;
}



.highlights.container {
  display: flex;
  .video-highlights {
    width: 50%;
    padding: 15px;
    video {
      max-width: 100%;
    }
  }
  .image-highlights {
    width: 50%;
    padding: 15px;

  }
}

.gfield_required {
  display: none !important;
}

.gform_wrapper legend.gfield_label, .gform_wrapper .top_label .gfield_label {
  text-transform: uppercase;
  font-family: 'Abolition', sans-serif;
  font-weight: 100;
  margin: 0;
}
body .gform_wrapper .top_label div.ginput_container {
  margin-top: 0 !important;
}

.ginput_container_textarea {
  width:calc(50% - 8px);
}
#gform_wrapper_1{
  .gform_button {

  }
}
.gform_body {
  input, textarea {
    border-radius: 0;
  }
}
.single {
  .game_overview  {
    background: #E0E0E0;
  }
  .game_overview .game_overview_wrap .game_info_box .ticket_section .recap_btn {
    height: 100%;
  }
}

.bx-wrapper .bx-pager {
  text-align: left;
  .bx-pager-item {
    a {
      background: $c_white;
      width: 33px;
      height: 5px;
      border-radius: 0;
      margin: 0;
      margin-right: 10px;
      &.active {
        background: $c_red;
      }
    }
  }
}



.player_company_logos {
	margin-top: 50px;

		@include breakpoint(laptop) {
				margin-top: 0px;
			}

	.company_logos_wrap {
				padding: 20px 20px;
				h2 {
						margin: 0 auto;
				    max-width: 1200px;
				}
		.company_logo_grid {
			display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 20px;

				@include breakpoint(tablet) {
				display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 20px;
			}

			@include breakpoint(laptop) {
				display: grid;
					grid-template-columns: repeat(7, 1fr);
					grid-gap: 20px;
					margin: 0 auto;
					max-width: 1200px;
			}

			.company_logo_img {
						height: 120px;
						a {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							height: 100%;
						}
					 img {
								width: 80%;

						}
			}
		}
	}
}




.honours_box {
	background: $c_red;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	padding: 10px 0;
	max-width: 100vw;
	flex-wrap: wrap;
	&.add_margins {
		margin: 0 0 70px 0;
	}
	.honour {
		margin: 0 5px;
		cursor: pointer;
		.icon {
			min-width: 25px;
			display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
			svg {
				fill: $c_white;
				width: 25px;
			}
		}
		.text {
			display: none;
			position: absolute;
			.arrow {
				margin-bottom: -10px;
				margin-left: -87.5px;
				width: 200px;
				text-align: center;
			}
			p {
				background: $c_black;
				margin: 0;
				text-align: center;
				width: 200px;
				padding: 5px;
				margin-left: -87.5px;
				font-family: 'Abolition', sans-serif;
				font-size: 1.2em;

			}
		}
		&:hover {
			.icon {
				svg {
					fill: $c_gold;
				}
			}
			.text {
				display: block;
				p {
					color: $c_white;
				}
			}
		}
	}
}

.animated_box_fade_in {
	opacity: 0;
	transition: all ease 1s;
}
.contact_us_page  {
	.contact_us_form_full {
		.form_wrap {
			.gform_wrapper {
				.gform_body {
					 .gfield {
						width: 100%;
						padding-right: 0;
					}
				}
				.gform_footer  {
					.gform_button  {
						width: 100%;
						border: 0;
						border-radius: 0;
						background-color: $c_red;
						color:$c_white;
						text-transform: uppercase;
						font-weight: 700;
						font-family: 'Abolition', sans-serif;
						font-size: 1.3em;
				    padding: 10px 5px 13px 5px;
					}
				}
			}
		}
	}
}

.full_image img{
	width: 100%;
}

.player_header a,
.image_text_block_two .image_text_block_two_wrap .text_block a,
 {
	color: white;
	font-weight: bold;
	text-decoration: underline;
	&:hover {
		opacity: 0.7;
	}
}

.ReactModalPortal .inplayer-paywall input {
    border: 0px !important;
    background: none !important;
    width: 100% !important;
    height: 35px !important;
}

#mobile-primary-menu .menu_item.menu_btn{
	@media(max-width: 767px) {
		display: none;
	}
}
